// import { theme as defaultTheme } from "@chakra-ui/react"

// const theme = {
//   ...defaultTheme,
//   colors: {
//     ...defaultTheme.colors,
//     brandPurple: "#673FB4",
//   },
// }
import { extendTheme } from "@chakra-ui/react"
const theme = {
   styles: {
      global: {
         // styles for the `body`
         body: {
            color: "#333333",
            fontSize: "62.5%",
         },
         // styles for the `a`
         // a: {
         //    color: "teal.500",
         //    _hover: {
         //       textDecoration: "underline",
         //    },
         // },
      },
   },
   fonts: {
      heading: "游ゴシック体, Yu Gothic, YuGothic, ヒラギノ角ゴ Pro, Hiragino Kaku Gothic Pro, メイリオ, Meiryo, sans-serif"
   },
   colors: {
      brandPurple: "#5eb43f"
   },
   
}
export default extendTheme(theme)