// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-channeldetail-js": () => import("./../../../src/pages/channeldetail.js" /* webpackChunkName: "component---src-pages-channeldetail-js" */),
  "component---src-pages-channellist-js": () => import("./../../../src/pages/channellist.js" /* webpackChunkName: "component---src-pages-channellist-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-disclaimers-js": () => import("./../../../src/pages/disclaimers.js" /* webpackChunkName: "component---src-pages-disclaimers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-test-001-js": () => import("./../../../src/pages/test001.js" /* webpackChunkName: "component---src-pages-test-001-js" */),
  "component---src-pages-videodetail-js": () => import("./../../../src/pages/videodetail.js" /* webpackChunkName: "component---src-pages-videodetail-js" */),
  "component---src-pages-videolist-js": () => import("./../../../src/pages/videolist.js" /* webpackChunkName: "component---src-pages-videolist-js" */),
  "component---src-templates-blog-post-all-archive-js": () => import("./../../../src/templates/blog-post-all-archive.js" /* webpackChunkName: "component---src-templates-blog-post-all-archive-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

